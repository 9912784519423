import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="hero margin-top-0 has-overlay section background--image background--fixed"
          style={{
            backgroundImage: `url('/img/bayreuth-maxstrasse.jpg')`,
          }}
        >
          <div className="container level">
            <h1 className="s-size-1">
              Aktuelles
          </h1>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
