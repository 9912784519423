import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`blog-list-item is-child ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
              >
                {post.frontmatter.featuredimage ? (
                  <Link to={post.fields.slug}>
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.title}`,
                        }}
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </Link>
                ) : null}
                <header>
                  <div>
                    <div className="post-meta">
                      <Link
                        className="title is-4 is-spaced"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>

                      <p className="subtitle date is-size-6 ">
                        {post.frontmatter.date}
                      </p>
                    </div>
                  </div>
                </header>
                <p>
                  {post.excerpt}
                  <br />
                  <br />
                  <Link className="button " to={post.fields.slug}>
                    Weiterlesen →
                  </Link>
                </p>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "DD.MM.YYYY", locale: "de")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
